<template>
  <router-link :to="{name: 'registry.view', params: {id: value}}">
    <registry-type-name
      style="text-decoration: underline;"
      :value="value"
      :short="short"
      :cut-tlds="cutTlds"
      :max-tlds="maxTlds"/>
  </router-link>
</template>

<script>
  import RegistryTypeName from './RegistryTypeName'

  export default {
    components: {
      RegistryTypeName
    },

    props: {
      value: {
        type: String,
        default: undefined
      },
      short: Boolean,
      cutTlds: Boolean,
      maxTlds: {
        type: Number,
        default: 2
      }
    }
  }
</script>
