<messages>["./RegistryObjectCreateUpdate"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (`delete.${deleteObject.objectType}.title`)"
    :error-msg="errorMsg"
    :is-loading="isLoading"
    is-delete
    @ok="request">
    {{
      $t (
        `delete.${deleteObject.objectType}.message`,
        {name: deleteObject.name, refId: deleteObject.refId}
      )
    }}
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  export default {
    name: 'RegistryObjectDeleteDialog',

    components: {
      ConfirmationDialog
    },

    props: {
      deleteObject: {
        type: Object,
        required: true,
        validator (value) {
          return ['objectType', 'url', 'v_id', 'name'].every ((key) => {
            return value[key] !== undefined
          })
        }
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: ''
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          if (!newValue) {
            this.errorMsg = ''
          }

          this.$emit ('input', newValue)
        }
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      request () {
        this.isLoading = true

        if (this.deleteObject.url) {
          this.fetchData ({
            op: this.deleteObject.url,
            params: {
              id: this.deleteObject.v_id
            },
            cb: data => {
              this.isLoading = false
              this.displaySuccessMessage (data.accepted
                ? this.$t (`delete.${this.deleteObject.objectType}.accepted`)
                : this.$t (`delete.${this.deleteObject.objectType}.success`))
              this.$emit ('deleted')
              this.showDialog = false
            },
            cbError: data => {
              console.log (data)
              if (data.errors ? data.errors.length > 0 : false) {
                this.errorMsg = data.errors[0].message
              } else {
                this.errorMsg =
                  this.$t (`delete.${this.deleteObject.objectType}.error`)
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      }
    }
  }
</script>
