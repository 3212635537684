<messages>["@/app/i18n/common/GenericObject", "../Host"]</messages>

<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="host"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <!-- filter form -->

    <template #basic>
      <v-row>
        <v-col
          class="pa-1"
          cols="12">
          <v-text-field
            ref="domainName"
            v-model.trim="filterParams.domainName"
            name="domainName"
            :label="$t (`label.domainName`)"
            clearable/>
        </v-col>
        <v-col
          v-if="isClientSelectable"
          class="pa-1"
          cols="12" sm="12">
          <client-select
            ref="clientId"
            v-model="filterParams.clientId"
            show-inactive
            for-view
            nullable/>
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          :class="{'col-sm-12': isClientSelectable}">
          <registry-select
            ref="registryTypes"
            v-model="filterParams.registryTypes"
            include-inactive
            multiple
            clearable
            :label="$t('label.registryTypes')"
            :hint="$t ('label.registryTypesHint')"/>
        </v-col>
      </v-row>
    </template>
    <template #additional>
      <v-row>
        <v-col v-bind="max1Attrs">
          <v-text-field
            ref="handle"
            v-model.trim="filterParams.handle"
            name="handle"
            :label="$t (`label.handle`)"
            clearable/>
        </v-col>

        <!-- host state filter -->
        <v-col v-bind="max1Attrs">
          <v-autocomplete
            ref="hostState"
            v-model="filterParams.hostState"
            :label="$t ('state.label')"
            :items="hostStates"/>
        </v-col>
      </v-row>
    </template>
  </search-filter-wrapper>
</template>

<script>
  import {mapGetters} from 'vuex'

  import ClientSelect from '@/app/core/components/ClientSelect'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import SearchFilterWrapper, {
    max1Attrs
  } from '@/app/core/components/Search/SearchFilterWrapper'

  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'

  /**
   * default host search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    handle: null,
    registryTypes: [],
    domainName: null,
    hostState: 'active',
    clientId: null
  })

  export default {
    name: 'HostFilter',

    components: {
      ClientSelect,
      RegistrySelect,
      SearchFilterWrapper
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      InputHelper,
      QuickFilterMixin
    ],

    data () {
      return {
        max1Attrs,
        additionalFilterKeys: ['hostState', 'handle'],
        basicFilter: ['domainName', 'clientId', 'registryTypes']
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayViewAllObjects',
        'mayViewSubEntitiesObjects',
        'hasSubClients'
      ]),

      isClientSelectable () {
        return this.mayViewAllObjects ||
          (this.mayViewSubEntitiesObjects && this.hasSubClients)
      },

      hostStates () {
        return ['active', 'deleted', 'any'].map (s => ({
          text: this.$t (`state.${s}`),
          value: s
        }))
      },

      basicFilterLabels () {
        return this.basicFilter.map ((label) => {
          return this.$t (`label.${label}`)
        })
      }
    }
  }
</script>
