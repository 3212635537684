<messages>["@/app/i18n/common/GenericObject", "./Host"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <!-- filters -->
    <v-col cols="12" lg="8" xl="4">
      <!-- search filter -->
      <host-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!-- host list -->
    <v-col cols="12" xl="8">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        type="info"
        color="primary"/>

      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <host-table
            v-bind="paginationState"
            :host-data="resultList"
            :loading="isLoading"
            :rows-per-page-items="rowsPerPageItems"
            :total-count="totalCount"
            @delete="openConfirmDeleteDialog"
            @shift="openShiftDialog"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"
            @states="openStatesDialog"
            @sync="syncHosts"/>
        </v-card-text>
        <registry-object-delete-dialog
          v-if="deleteObject"
          v-model="isDeleteDialogVisible"
          :delete-object="deleteObject"
          @deleted="load"/>
        <states-dialog
          v-if="hostObj"
          v-model="statesDialog"
          type="host"
          :object="hostObj"
          @success="load"/>
        <simple-shift-dialog
          v-if="hostObj"
          v-model="shiftDialog"
          type="host"
          :shift-object="hostObj"
          @shifted="load"/>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapMutations} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import RegistryObjectDeleteDialog
    from '@/app/core/components/RegistryObject/RegistryObjectDeleteDialog'
  import HostFilter from './components/HostFilter'
  import HostTable from './components/HostTable'

  import SearchPage from '@/app/core/mixins/SearchPage'
  import StatesDialog from '@/app/core/components/RegistryObject/StatesDialog'

  import SimpleShiftDialog
    from '@/app/core/components/RegistryObject/SimpleShiftDialog'

  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'HostSearch',

    components: {
      CsvDownloadBtn,
      BaseLayout,
      HostFilter,
      HostTable,
      RegistryObjectDeleteDialog,
      StatesDialog,
      SimpleShiftDialog
    },

    mixins: [SearchPage],

    // define standard sorting properties (override property definitions from
    // `SearchPage` mixin)
    props: {
      sortBy: {
        type: String,
        default: 'handle'
      }
    },

    data () {
      return {
        // this value is used by mixin for sending requests to the BE API
        OPERATION: 'host/list',
        DELETE_OBJECT_STATIC_PROPS: {
          url: 'host/delete',
          objectType: 'host'
        },
        hostObj: null,
        statesDialog: false,
        shiftDialog: false
      }
    },

    computed: {
      ...mapState ('filter', [
        'hostSearchQuery'
      ])
    },

    methods: {
      // --- mutation, mapped from vuex ----------------------------------------
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        storeSearchQuery: 'filter/setHostSearchQuery'
      }),

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.hostSearchQuery
      },

      /**
       * Synchronize hots with the registry
       *
       * @param {Array} elements   hosts with domainName and registryId
       */
      syncHosts (elements) {
        this.fetchData ({
          op: 'host/sync',
          params: {
            hosts: elements
          },
          cb: data => {
            const count = Object.keys (data.names).length
            this.displaySuccessMessage (
              this.$tc ('sync.success'),
              count, {
                count
              })

            this.load ()
          }
        })
      },

      openStatesDialog (e) {
        this.hostObj = {
          ...e
        }
        this.statesDialog = true
      },

      openShiftDialog (e) {
        this.hostObj = {
          ...e
        }
        this.shiftDialog = true
      }
    }
  }
</script>
