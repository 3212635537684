<messages>["@/app/i18n/common/GenericObject", "../Host"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="hostData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount"
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          <router-link
            :to="detailsLink (props.item)"
            :class="{deletedItemLink: props.item.deleted}"
            v-text="props.item.domainName"/>
        </td>
        <td>
          {{ props.item.refID }}
        </td>
        <td>
          <registry-type-link :value="props.item.registryType" cut-tlds/>
        </td>
        <td>
          <client-link :id="props.item.clientId"/>
        </td>
        <td>
          <span>{{ formatDate (props.item.creationDate) }}</span>
        </td>
        <td>
          <!--suppress JSCheckFunctionSignatures -->
          <action-buttons
            :value="isActionButtonsActive (props.item.versionId)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.versionId) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import registryCommands from '@/app/core/mixins/RegistryCommands'

  import {mapGetters} from 'vuex'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'

  import {byRegistryHostName as getDomainSearchFilterByRegistryHostName}
    from '@/app/pages/Domain/components/DomainFilter'

  import {isDeleteProhibited, isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  export default {
    name: 'HostTable',

    components: {
      ActionButtons,
      ClientLink,
      RegistryTypeLink,
      SearchTableNoData
    },

    mixins: [paginationMixins, actionButtonsHelper, registryCommands],

    props: {
      totalCount: {type: Number, default: 0},
      hostData: {type: Array, default: () => []},
      rowsPerPageItems: {
        type: Array,
        default: undefined
      },
      loading: Boolean
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions',
        hasSubClients: 'auth/hasSubClients',
        mayManageForeignObjects: 'auth/mayManageForeignObjects'
      }),

      headers () {
        return [
          {
            text: this.$t ('label.domainName'),
            value: 'domainName'
          },
          {
            text: this.$t ('label.roid'),
            value: 'handle'
          },
          {
            text: this.$t ('label.registryType'),
            sortable: false
          },
          {
            text: this.$t ('label.clientId'),
            value: 'clientId'
          },
          {
            text: this.$t ('label.creationDate'),
            value: 'creationDate'
          },
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      }
    },

    methods: {
      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        const view = [{
          action: 'view',
          registry: item.registryType,
          handle: item.refID,
          hostVersionId: item.versionId,
          hostDeleted: item.deleted,
          icon: 'visibility',
          tooltip: this.$t ('general.button.view')
        }]

        const edit = [{
          action: 'edit',
          registry: item.registryType,
          handle: item.refID,
          hostVersionId: item.versionId,
          hostDeleted: item.deleted,
          icon: 'edit',
          disabled: !this.supports (item.registryType, 'HostModify') ||
            item.deleted || !(this.mayManageObject (item.clientId)) ||
            isUpdateProhibited (item.states),
          tooltip: this.$t ('general.button.edit')
        }]

        const states = [{
          ...item,
          action: 'states',
          disabled:
            !this.supports (item.registryType, 'HostStatusModify') ||
            item.deleted || !(this.mayManageObject (item.clientId)),
          icon: 'settings',
          tooltip: this.$t ('general.button.manageStates')
        }]

        const sync = [...this.isAdmin
          ? [{
            action: 'sync',
            element: {name: item.domainName, registryId: item.registryType},
            disabled: !this.supports (item.registryType, 'HostInquire'),
            icon: 'sync',
            tooltip: this.$t ('general.button.sync')
          }]
          : []]

        const deleteAction = [{
          action: 'delete',
          actionArg: [item],
          registry: item.registryType,
          hostVersionId: item.versionId,
          name: item.domainName,
          handle: item.refID,
          icon: 'delete',
          tooltip: item.deletable
            ? this.$t ('host.delete.inUse')
            : this.$t ('general.button.delete'),
          disabled: !this.supports (item.registryType, 'HostDelete') ||
            item.deleted || !(this.mayManageObject (item.clientId)) ||
            isDeleteProhibited (item.states),
          color: 'error'
        }]

        const associated = [{
          action: 'associated',
          registry: item.registryType,
          hostname: item.domainName,
          icon: 'link',
          tooltip: this.$t ('general.button.associated')
        }]

        const shift = [...this.mayManageForeignObjects && this.hasSubClients
          ? [{
            action: 'shift',
            actionArg: [item],
            icon: 'swap_vert',
            tooltip: this.$t ('general.button.shift'),
            handle: item.refID,
            hostVersionId: item.versionId,
            hostId: item.id,
            disabled: item.deleted || item.pendingDeletion ||
              !(this.mayManageObject (item.clientId))
          }]
          : []
        ]

        return [
          ...view,
          ...states,
          ...edit,
          {divider: true},
          ...deleteAction,
          {divider: true},
          ...associated,
          ...shift,
          ...sync // admins only
        ]
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        const params = (({registry, handle}) => ({registry, handle})) (button)

        switch (button.action) {
          case 'view':
            this.$router.push (
              button.hostDeleted
                ? {
                  name: 'host.view.version',
                  params: {vid: button.hostVersionId}
                }
                : {
                  name: 'host.view',
                  params
                })
            break
          case 'shift':
            this.$emit (
              'shift',
              {
                id: button.hostId,
                name: button.handle
              }
            )
            break
          case 'edit':
            this.$router.push ({
              name: 'host.edit',
              params
            })
            break
          case 'states':
            this.$emit (
              'states',
              {
                v_id: button.versionId,
                id: button.id,
                name: button.domainName
              }
            )
            break
          case 'associated':
            this.$router.push ({
              name: 'domain.search',
              query: {
                filter: JSON.stringify (
                  getDomainSearchFilterByRegistryHostName (
                    button.registry, button.hostname))
              }
            })
            break
          case 'sync':
            this.$emit ('sync', [button.element])
            break
          case 'delete':
            this.$emit (
              'delete',
              {
                v_id: button.hostVersionId,
                refId: button.handle,
                name: button.name
              }
            )
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * Generate the link data for the host details view.
       *
       * @param {Object} host     the host to create the link for
       */
      detailsLink (host) {
        return host.deleted
          ? {
            name: 'host.view.version',
            params: {vid: host.versionId}
          }
          : {
            name: 'host.view',
            params: {
              registry: host.registryType,
              handle: host.refID
            }
          }
      },

      /**
       * handle pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      }
    }
  }
</script>>
